:root {
	--languageSelector-button-color: var(--cs1-dark);
	--languageSelector-button-active-bgcolor: var(--cs1-grey-darker);
	--languageSelector-button-active-color: var(--main-white);
	--languageSelector-button-active-hover: var(--main-white);
}
[data-theme="dark"] {
	--languageSelector-button-color: var(--cs1-black);
	--languageSelector-button-active-bgcolor: var(--cs2-green);
}

.container {
	display: inherit;
}
.container button {
	width: 100%;
	font-size: inherit;
	padding: 6px 30px;
	text-align: left;
	font-weight: normal;
	text-transform: capitalize;
	color: var(--languageSelector-button-color);
}
.container button:hover {
	border: none;
	text-decoration: underline;
	box-shadow: none;
}
button.active {
	background-color: var(--languageSelector-button-active-bgcolor);
	color: var(--languageSelector-button-active-color);
}
button.active:hover {
	color: var(--languageSelector-button-active-hover);
}
