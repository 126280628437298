:root {
	--button-mainColor: var(--cs1-dark);
	--button-settingsPath: var(--cs1-dark);
	--button-settings-hover: var(--cs1-grey);
}
[data-theme="dark"] {
	--button-mainColor: var(--cs2-light);
	--button-settingsPath: var(--cs2-green);
	--button-settings-hover: var(--cs2-black);
}
.btn button {
	color: var(--button-mainColor);
	border: 0;
	text-transform: uppercase;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	visibility: visible;
	opacity: 1;
	font-weight: bold;
	position: relative;
	background: none;
	font-family: "Inter", sans-serif;
	transition: all 0.2s ease-in-out;
}
.btn button:hover {
	cursor: pointer;
}

/* Custom Styling */
/* .settings button,  */
.close button {
	/* top: 10px;
	right: 10px; */
	top: 0.5em;
	right: 0.5em;
	position: fixed;
	/* padding: 6px 8px; */
	padding: 6px;
	/* border-radius: 10px; */
	z-index: 10;
}

/* .locationSelector {
	display: flex;
	justify-content: center;
} */
/* .locationSelector button:hover {
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
} */
.locationSelector button {
	display: flex;
	/* padding: 12px 18px 12px 16px; */
	padding: 10px 28px 10px 28px;
	/* font-size: small; */
	justify-content: center;
	align-content: center;
	align-items: center;
	/* border-radius: 0.35rem; */
	border-radius: var(--default-radius-32);
	/* border-radius: var(--default-radius-10); */
	box-shadow: 0 2px 10px var(--main-black-opacity05),
		0 1px 2px var(--main-black-opacity1);
	background-color: var(--languageSelector-button-active-bgcolor);
	color: var(--languageSelector-button-active-color);
	margin: 12px 0;
	z-index: 1;
}
/* .locationSelector span {
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 24px;
} */
.locationSelector button:hover time {
	opacity: 0.8;
}
.locationSelector svg {
	width: 18px;
	height: 18px;
	left: 0;
	position: absolute;
}
.locationSelector path {
	fill: var(--languageSelector-button-active-color);
}
/* Settings */
/* .settings {
	padding: 6px 10px;
	border-radius: var(--default-radius);
} */
.settings button svg {
	width: 18px;
	height: 18px;
}
.settings path {
	fill: var(--button-settingsPath);
}
.settings button {
	font-size: inherit;
	font-weight: normal;
	/* line-height: 2em; */
	text-transform: capitalize;
	/* text-decoration: underline; */
	display: flex;
	/* justify-content: center; */
	align-items: center;
	/* padding: 6px; */
	/* padding: 8px 10px; */
	padding: 8px 0;
	/* padding: 10px; */
	/* border-radius: var(--default-radius); */
	/* margin: 12px 0; */
	/* background-color: var(--button-settings-hover); */
}

.settings button:hover,
.settings.active button {
	/* background-color: var(--button-settings-hover); */
	/* transform: scale(1.1); */
	text-decoration: underline;
}
/* @end Custom Styling */
