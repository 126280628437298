:root {
	/* --locationSelector-anchor-color: var(--cs1-grey-darker); */
	--locationSelector-anchor-color: var(--main-white);
	--locationSelector-anchor-backgroundColor: var(--cs1-grey-darker);
}
[data-theme="dark"] {
	--locationSelector-anchor-backgroundColor: var(--cs2-green);
}
.locationSelector {
	position: relative;
	/* max-width: 100%; */
	transition-duration: 300ms;
	/* margin: 0 auto; */
	/* padding-top: 3.8em; */
	padding-top: 2.5em;
	overflow: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	height: 100%;
	/* height: calc(100% - (2em)); */
	scrollbar-width: thin;
}
@media screen and (min-width: 400px), screen and (orientation: landscape) {
	.locationSelector {
		padding-top: 0;
		overflow-y: auto;
	}
}

.locationSelector ul {
	width: 100%;
}
.locationSelector li ul {
	top: 0;
	/* top: 50px; */
	bottom: 0;
	left: 100%;
	display: none;
	position: absolute;
	overflow: auto;
	/* height: 100%; */
}
.locationSelector ul.active {
	transition-duration: 300ms;
	left: -100%;
}
.locationSelector > ul {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	left: 0;
	position: relative;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}
.locationSelector > ul li,
.locationSelector a {
	flex: 1 1;
	display: flex;
	justify-content: center;
}
.locationSelector a {
	padding: 6px 24px;
	flex-direction: column;
	font-size: 0.8em;
}
.locationSelector a:hover {
	cursor: pointer;
}
.locationSelector a.active {
	color: var(--locationSelector-anchor-color);
	background-color: var(--locationSelector-anchor-backgroundColor);
}
.locationSelector ul.stateActive {
	display: block;
}

/* Home button */
.home a {
	padding-top: 14px;
	padding-bottom: 14px;
	flex-direction: row;
	justify-content: flex-start;
}
.home a:before {
	content: "←";
	width: 20px;
	height: 20px;
	margin-right: 12px;
}
