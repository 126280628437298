:root {
	--loadingBar-AA-color: var(--cs1-dark);
	--loadingBar-background: var(--main-white);
	--loadingBar-spinner-backgroundColor: var(--cs1-dark);
}
[data-theme="dark"] {
	--loadingBar-AA-color: var(--cs2-green);
	--loadingBar-background: var(--cs2-grey);
	--loadingBar-spinner-backgroundColor: var(--cs2-green);
}

.loadingBarContainer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: var(--main-fontColor);
	/* background: url("../src/images/loadingBar.svg") 0 0 no-repeat; */
	background-color: var(--loadingBar-background);
	/* background-size: 50%; */
	z-index: 8;
	/* padding: 10px 0 10px 40px; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: var(--full-height);
	font-size: 0.8em;
}
.spinner {
	align-items: center;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
}
.spinner path {
	fill: var(--loadingBar-spinner-backgroundColor);
}

.loadingBarContainer footer {
	/* padding-bottom: 28px; */
	position: relative;
	bottom: 36px;
	font-size: 0.8em;
	text-align: center;
	text-transform: capitalize;
}
.loadingBarContainer span:nth-child(3) {
	display: block;
}
.loadingBarContainer a {
	color: var(--loadingBar-AA-color);
	text-decoration: underline;
}
.loadingBarContainer a:hover {
	text-decoration: none;
}
