:root {
	--prayerCountdown-header: var(--cs1-dark);
}
[data-theme="dark"] {
	--prayerCountdown-header: var(--cs2-light);
}
.container h2 {
	color: var(--prayerCountdown-header);
}
.container h2 > p {
	display: inline-block;
}
.container h2 > p:first-letter {
	text-transform: capitalize;
}
.container b {
	/* line-height: 1.8em;
	background:red; */
	text-transform: capitalize;
}
