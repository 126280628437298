:root {
	--prayerTimeList-salahName: var(--cs1-dark);
	--prayerTimeList-salahTime: var(--cs1-grey-darker);
	--prayerTimeList-notification-icon: var(--cs1-dark);
	--prayerTimeList-notificationDisable-icon: var(--cs1-dark);
}
[data-theme="dark"] {
	--prayerTimeList-salahName: var(--cs2-green);
	--prayerTimeList-salahTime: var(--cs2-light);
	--prayerTimeList-notification-icon: var(--cs2-green);
	--prayerTimeList-notificationDisable-icon: var(--cs2-green);
}

.container {
	height: 100%;
	/* height: calc(100% - 20%); */
	/* width: calc(100% - 60px); */
	/* max-width: 800px; */
	/* height: calc(100%-30%); */
	/* margin: 0 auto; */
	/* width: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* border-radius: var(--default-radius) var(--default-radius) 0px 0px; */
}
.container ul {
	/* height: 100%; */
	/* height: calc(100% - 20%); */
	height: calc(100% - 40%);
	margin: auto 0;
	/* height: 50%; */
	display: grid;
	/* grid-row-gap: 1px;
	grid-column-gap: 1px; */
	grid-template-columns: repeat(auto-fill, minmax(var(--minmaxval), 1fr));
	margin-left: 12%;
	margin-right: 12%;
}
@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
	.container ul {
		grid-row-gap: 16px;
	}
}
@media screen and (min-width: 768px) {
	/* .container label {

		background: blue;
	} */
	.container ul {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media screen and (min-width: 992px) {
	/* .container label {
		background: yellow;
	} */
	.container ul {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media screen and (min-width: 1200px) {
	/* .container label {
		background: green;
	} */
	.container ul {
		/* grid-template-columns: repeat(6, 1fr); */
		grid-template-columns: repeat(3, 1fr);
	}
}
/* @media screen and (max-width: 400px) {
	iPhone 7 / Huawei
	:root {
		--minmaxval: 150px;
	}
} */
.container li {
	position: relative;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	background-color: var(--main-bg);
}
/* .currentPrayerTime .salahName:before {
	content: " ";
	position: absolute;
	padding: 8px;
	background: rgb(105, 231, 129);
	background: linear-gradient(
		180deg,
		rgba(105, 231, 129, 1) 0%,
		rgba(0, 161, 82, 1) 100%
	);
	border-radius: 8px;
} */
.container label {
	-webkit-tap-highlight-color: rgba(
		0,
		0,
		0,
		0
	); /* remove grey background when click label */
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.container label:hover {
	cursor: pointer;
}
.container label:hover > .salahName {
	opacity: 0.8;
}
.notificationDisable,
.container input[type="checkbox"],
.enableNotification input[type="checkbox"]:checked + label .notification {
	display: none;
}
.enableNotification
	input[type="checkbox"]:checked
	+ label
	.notificationDisable {
	display: inline;
}
.notification path {
	fill: var(--prayerTimeList-notification-icon);
}
.notificationDisable path {
	fill: var(--prayerTimeList-notificationDisable-icon);
}
.salahName {
	color: var(--prayerTimeList-salahName);
	position: relative;
	font-size: 18px;
	font-weight: bold;
	margin: 0 20%;
	/* display: block; */
}
.salahName span {
	position: relative;
}
.salahTime {
	font-weight: normal;
	text-transform: uppercase;
	font-size: 14px;
	display: block;
	color: var(--prayerTimeList-salahTime);
}
.icon {
	width: 18px;
	height: 18px;
	/* left: 0; */
	/* top: 12px; */
	left: -24px;
	position: absolute;
	-moz-transform: rotate(20deg);
	-webkit-transform: rotate(20deg);
	-o-transform: rotate(20deg);
	-ms-transform: rotate(20deg);
	transform: rotate(20deg);
}
