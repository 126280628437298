:root {
	--modal-background-color: var(--main-white);
}

.overlay {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 11;
	background-color: var(--main-black-opacity5);
}

.modal {
	-webkit-overflow-scrolling: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	/* 10% occupied by padding */
	/* width: 90%;  */
	/*
	border-radius: var(--default-radius);
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) scale(0.9);
	-moz-transform: translate(-50%, -50%) scale(0.9);
	-ms-transform: translate(-50%, -50%) scale(0.9);
	-o-transform: translate(-50%, -50%) scale(0.9);
	transform: translate(-50%, -50%) scale(0.9); */
	overflow-y: auto;
	overflow-x: hidden;
	visibility: hidden;
	opacity: 0;
	/* -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s; */
	z-index: 10;
	background-color: var(--modal-background-color);
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 1px rgba(0, 0, 0, 0.18);
	/* width: 100%; */
	/* height: 100%; */
}
/* .modal .overlay {
	content: "";
	display: block;
	position: fixed;
	inset: 0px;
	background-color: rgba(255, 255, 255, 0.75);
} */
/* .modal {
	width: calc(80%);
	height: auto;
	max-width: 900px;
} */
/* .modal .content {
	min-height: inherit;
} */
.modal .content {
	height: 100%;
	width: 100%;
	position: relative;
}

.modal .content g {
	fill: red;
}

.modal.open {
	visibility: visible;
	opacity: 1;
}

@media screen and (min-width: 400px) {
	.modal {
		-webkit-overflow-scrolling: auto;
		border-radius: var(--default-radius);
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%) scale(0.9);
		-moz-transform: translate(-50%, -50%) scale(0.9);
		-ms-transform: translate(-50%, -50%) scale(0.9);
		-o-transform: translate(-50%, -50%) scale(0.9);
		transform: translate(-50%, -50%) scale(0.9);
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		/* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2); */
		visibility: hidden;
		opacity: 0;
		z-index: 10;
		width: 100vw;
		height: 100vh;
		/* max-width: 800px; */
		/* height: 80%; */
		/* height: calc(100% - (40px)); */
		/* height: calc(100% - (20vh)); */
	}
	.modal .content {
		width: 100%;
		/* max-width: 900px; */
		overflow: hidden;
		position: relative;
	}
	.modal .content .container {
		/* border-top: 1px solid transparent; */
		/* border-top: 1px solid var(--main-border-color); */
		border-bottom: 1px solid var(--main-border-color);
		/* margin: 38px 0; */
		margin: 2.5em 0;
		/* height: calc(100% - (80px)); */
		height: calc(100% - (5em));
		/* height: 100%; */
		/* max-height: 56vh; */
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
}
/* @media (min-width: 768px) {
	.modal {
		width: calc(80%);
		height: auto;
		max-width: 900px;
	}
	.modal .content {
		min-height: inherit;
	}
} */

/* @media (min-width: 480px) {
	.modal .content .container {
		padding: 50px 20px 80px;
		background: red;
		padding: 50px 20px;
	}
} */
@media screen and (min-width: 768px) {
	.modal {
		width: calc(80%);
		height: auto;
		max-width: 900px;
	}
	.modal .content {
		min-height: inherit;
	}
}
@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
	.modal {
		width: 100vw;
		height: 100vh;
	}
}
/*
@media (min-width: 992px) {
	.modal .content .container {
		background: green;
	}
} */

/* .bounceIn {
	animation-name: bounceIn;
	animation-duration: 250ms;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}

@keyframes bounceIn {
	from,
	50%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	from {
		opacity: 0.2;
		transform: scale3d(0.6, 0.6, 0.6);
	}
	50% {
		transform: scale3d(1.1, 1.1, 1.1);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
} */

/* end Popup.less */
