.datePicker {
	/* position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	font-size: 12px;*/
	/* display: flex;
	justify-content: center;
	align-items: center; */
	/* margin-right: 8px; */
	/* flex: 1; */
	/* color: var(--button-mainColor); */
	color: var(--main-white);
	font-size: 0.8em;
	font-weight: 300;
	display: block;
	margin-top: 4px;
}
/* .datePicker span {
	display: block;
} */
