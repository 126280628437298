@import url("https://rsms.me/inter/inter.css");
* {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	scrollbar-width: none;
}
html {
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: subpixel-antialiased;
	scroll-behavior: smooth;
}
html,
body {
	width: 100%;
	height: 100%;
}

/*
@custom-media ---viewport-4;
@custom-media --viewport-7 (min-width: 768px);
@custom-media --viewport-9 (min-width: 992px);
@custom-media --viewport-12 (min-width: 1200px);
*/

:root {
	/* Main Colors */
	--main-white: white;
	--main-border-color: #e0e0e0;

	/* Color Scheme 1 Light Mode - https://colorhunt.co/palette/1649 */
	--cs1-light: #f0f5f9;
	--cs1-grey: #c9d6df;
	--cs1-grey-darker: #52616b;
	--cs1-dark: #40514e;

	/* Color Scheme 2 Dark Mode - https://colorhunt.co/palette/117601 */
	--cs2-light: #eee;
	--cs2-green: #4ecca3;
	--cs2-grey: #393e46;
	--cs2-black: #232931;

	/* Opacity */
	--main-black-opacity1: rgba(0, 0, 0, 0.1);
	--main-black-opacity2: rgba(0, 0, 0, 0.2);
	--main-black-opacity5: rgba(0, 0, 0, 0.5);
	--main-black-opacity05: rgba(0, 0, 0, 0.05);

	/* Light Mode */
	--main-bg: var(--cs1-light);
	--main-fontColor: var(--cs1-dark);
	--main-anchor-color: var(--cs1-dark);
	--main-footer-anchor-color: var(--cs1-grey-darker);
	--section-backgroundColor: var(--cs1-grey);
	--handlebarColor: var(--cs1-grey-darker);
	--settingsContainer-label-hover: var(--cs1-grey-darker);

	/* Structure */
	--full-height: 100vh;
	--header-height: 94vh;
	/* --minmaxval: 266px; */
	--minmaxval: 126px;
	--default-radius: 20px;
	--default-radius-10: 10px;
	--default-radius-32: 32px;
}

/* Dark Mode */
[data-theme="dark"] {
	/* @media (prefers-color-scheme: darl) { */
	--main-bg: var(--cs2-grey);
	--main-fontColor: var(--cs2-light);
	--main-anchor-color: var(--cs1-dark);
	--main-footer-anchor-color: var(--cs2-green);
	--section-backgroundColor: var(--cs2-black);
	--handlebarColor: var(--cs2-green);
	--settingsContainer-label-hover: var(--cs2-green);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
* {
	margin: 0;
	padding: 0;
	list-style: none;
}
html {
	font-family: "Inter", sans-serif;
}
body {
	color: var(--main-fontColor);
	min-height: var(--full-height);
	min-height: -webkit-fill-available;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 20px;
	letter-spacing: -0.017em;
	line-height: 28px;
	/* font-size: 24px;
	letter-spacing: -0.019em;
	line-height: 34px; */
}
a {
	text-decoration: none;
	color: var(--main-anchor-color);
}
a:hover {
	text-decoration: underline;
}
a:visited {
	text-decoration: underline;
}
a:hover {
	text-decoration: underline;
}
h2 {
	font-weight: normal;
	font-size: 1.2em;
}
.rtl {
	/* unicode-bidi: bidi-override !important; */
	direction: unset !important;
	/* text-align: right; */
	text-align: center;
}
/* invisible scroll bar */
.wrapper {
	overflow-y: scroll;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
}
.wrapper::-webkit-scrollbar {
	/* WebKit */
	width: 0;
	height: 0;
}
/* @end invisible scroll bar */
.wrapper {
	height: var(--full-height);
	min-height: -webkit-fill-available;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	background-color: var(--main-bg);
}
.isMinimal.wrapper {
	overflow-y: auto;
}
section.content {
	transform-style: preserve-3d;
	/* touch-action: none; */
	/* padding: 0 4%; */
	/* height: calc(var(--full-height) - 30vh); */
	/* background-color: var(--section-backgroundColor); */
	/* border: 2px solid rgba(0, 0, 0, 0.1); */
	/* border-radius: var(--default-radius) var(--default-radius) 0px 0px; */
	/* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); */
	/* box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1); */
	/* box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2); */
	/* pointer-events: none; */
	/* position: relative; */
	/* margin-left: 10px;
	margin-right: 10px; */
	/* align-items: center;
	z-index: 2; */
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	/* height: calc(100% - 2.3em); */
	height: var(--full-height);
	/* height: calc(var(--full-height) - var(--hey)); */
	/* z-index: 3; */
	/* scroll-snap-type: y mandatory; */
}
section.content .scroll-content-outer {
	/* left: -20px;
	right: -20px; */
	left: 0;
	right: 0;
	height: 100%;
	overflow-y: scroll;
	/* left: 0;
	right: 0; */
	position: absolute;
}
/* hide scrollbar */
::-webkit-scrollbar {
	display: none;
}
section.content .scroll-content-inner {
	background-color: var(--main-bg);
	/* left: 4%;
	right: 3%; */
	left: 16px;
	right: 16px;
	/* width: 100%; */
	position: absolute;
	/* top: 95%; */
	/* top: 88vh; */
	top: 95%;
	/* height: 100%; */
	height: 70%;
	/* scroll-snap-align: start;
	scroll-snap-stop: normal; */
	box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
	border-radius: var(--default-radius) var(--default-radius) 0px 0px;
	/* margin-left: auto;
	margin-right: auto; */
}
section.content .scroll-content-inner::before {
	content: " ";
	height: 5px;
	display: block;
	width: 40px;
	background-color: var(--handlebarColor);
	border-radius: 100px;
	position: absolute;
	left: 50%;
	/* top: 0; */
	top: 4px;
	transform: translateX(-50%);
}

/* Aside */
aside {
	/* color: var(--main-fontColor); */
	/* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2); */
	/* -moz-box-shadow: inset 0 -10px 10px -10px #000000;
	-webkit-box-shadow: inset 0 -10px 10px -10px #000000;
	box-shadow: inset 0 -10px 10px -10px #000000; */
	font-size: 12px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	/* top: 1%; */
	left: 0;
	right: 0;
	z-index: 11;
	padding: 8px 16px;
}
aside h1 {
	color: var(--button-mainColor);
}
aside .buttonContainer {
	position: relative;
	margin-left: 12px;
	display: flex;
	align-items: center;
}
/* @end Aside */

/* Custom CSS */
article {
	background-color: var(--main-black-opacity05);
}
/* header, */
/* header .content, */
header .subcontent,
section.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
}
/* header, */
/* section.content, */
/* .scroll-content-inner {
	scroll-snap-align: start;
	scroll-snap-stop: normal;
} */

/* header {
	position: relative;
	text-align: center;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
} */
header {
	height: var(--full-height);
}

/* header,
header .content {
	height: var(--full-height);
} */
.isMinimal header,
.isMinimal header .content {
	height: var(--full-height);
	/* min-height: -webkit-fill-available; */
}
.isMinimal header .settingsContainer .content {
	height: auto;
}
header .subcontent {
	/* top: 0; */
	align-items: center;
	/* margin: 0 10%; */
	/* position: sticky; */
	/* z-index: 5; */
	margin: auto;
	/* height: calc(var(--full-height) - 70vh); */
	/* height: var(--full-height); */
	/* height: var(--scrollingSize); */
	height: calc(var(--full-height) - var(--scrollingSize));
	/* transform: translateY(10%) */
}
/* header .subcontent.itemScroll {
	z-index: 1;
} */
h3.locationSelectorTitle {
	position: fixed;
	font-size: 1em;
	top: 1px;
	left: 0;
	right: 0;
	padding: 0 24px;
	height: 2.5em;
	display: flex;
	align-items: center;
	background-color: var(--main-white);
	z-index: 9;
	border-bottom: 1px solid var(--main-border-color);
	/* box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3); */
}

/* Credit Settings Container  */
.settingsContainer {
	/* overflow: hidden; */
	/* display: flex;
	flex-direction: column; */
	/* flex-wrap: wrap; */
	/* padding: 0 30px; */
	border-radius: var(--default-radius-10);
	text-transform: capitalize;
	/* padding: 12px 14px; */
	/* left: 96px; */
	/* left: 80px; */
	top: 3.2em;
	/* top: 90%; */
	/* right: -50px; */
	/* left: 0; */
	right: 0;
	/* bottom: 30px; */
	/* bottom: 28px; */
	padding: 12px 0;
	/* right: 0; */
	min-width: 160px;
	/* width: 100vw;
	max-width: 218px; */
	position: absolute;
	background-color: var(--main-white);
	/* box-shadow: 0 2px 10px 0 var(--main-black-opacity2); */
	box-shadow: 0 0 0 1px var(--main-black-opacity1),
		0 2px 4px 1px rgba(0, 0, 0, 0.18);
	z-index: 10;
	/* overflow: scroll;
	height: calc(var(--full-height) - 30vh); */
}
.settingsContainer .content {
	display: block;
	height: inherit;
}
.settingsContainer .content h3 {
	font-size: 10px;
	text-align: left;
	text-transform: uppercase;
	padding: 0 30px;
	margin: 4px 0;
	color: #ccc;
}

/* .settingsContainer.bahasa,
.settingsContainer.english,
.settingsContainer.arabic { */
.settingsContainer.languages {
	/* left: 138px; */
	width: 94px;
	left: -16px;
	/* left: 86px; */
}
.settingsContainer input {
	display: none;
	margin-right: 6px;
}
.settingsContainer svg {
	width: 12px;
	height: 12px;
	margin-right: 6px;
}
/* .settingsContainer svg #icons {
	display: none;
} */

/* .settingsContainer input[type="checkbox"]:checked + label > .notification { */
/* .settingsContainer input[type="checkbox"]:checked ~ svg #icons { */
/* + label .notification { */
/* visibility: visible; */
/* display: block;
	border: 1px solid;
} */

.settingsContainer label {
	display: flex;
	align-items: center;
	padding: 2px 12px;
	/* min-width: 136px; */
}
.settingsContainer a:hover,
.settingsContainer label:hover {
	color: var(--settingsContainer-label-hover);
	text-decoration: underline;
}
.settingsContainer label:hover {
	cursor: pointer;
}
.settingsContainer input[type="checkbox"]:checked + label svg path {
	fill: var(--main-black-opacity5);
	display: block;
}
.settingsContainer svg path {
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	fill: var(--main-black-opacity1);
	/* display: none; */
}

/* Footer */
footer {
	font-size: small;
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	color: var(--main-fontColor);
	border-top: 1px solid var(--main-black-opacity05);
	padding: 4px;
}
footer a {
	color: var(--main-footer-anchor-color);
}
